import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const UserManagement = lazy(() => import("./UserManagement"));
const UserPreferences = lazy(() => import("./UserPreferences"));

const pagesRoutes = [
  {
    path: "/user-management",
    component: UserManagement,
    auth: authRoles.admin,
    required_permissions: ["user_management_write"],
  },
  {
    path: "/user-preferences",
    component: UserPreferences,
    auth: authRoles.admin,
    required_permissions: ["user_management_write"],
  },
];

export default pagesRoutes;
